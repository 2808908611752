<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.visitDate }}
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="searchConditionsDatePicker"
                  format="YYYY-MM-DD"
                  v-model="visitDateValueModel"
                  type="lookup-condition"
                  :notClear="true"
                  :isGetDateInfo="true"
                  @change="handleSearchConditionsBsnDateChange"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      visitDateInfo.bsnCode,
                      visitDateInfo.dwCode
                    ),
                  }"
              >{{ dayOfWeekCaption }}</span
              >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      visitDateInfo.bsnCode
                    ),
                  }"
              >{{ commonCodesGetComName('BSN_CODE', visitDateInfo.bsnCode) }}</span>)
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened "
              @click.native="onViewMatchProgressTimetable"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">경기 진행 시간표</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      buttonDiv="SAVE"
                      :isShortcutButton="true"
                      :ignore="isPopupOpened"
                      @click.native="onSaveMatchProgressTimetable"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="PRINT"
                      :is-shortcut-button="true"
                      @click.native="print"
                      :ignore="isPopupOpened"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                ref="courseDivTab"
                :items="courseDivTabList"
                :showCloseButton="false"
                heightAdjustMode="Auto"
                overflowMode="Popup"
                @selected="tabSelected"
              />
            </div>
          </div>
          <div
            class="section-body"
            @keydown.capture="onMatchProgressTimetableGridSectionBodyKeyDown"
          >
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="matchProgressTimetableGrid"
                :allowExcelExport="true"
                :allowResizing="true"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :isAutoSelectCell="!isModifyMode"
                :provides="grid"
                :groupSettings="groupSettings"
                :columns="matchProgressTimetableGridColumn"
                :selectionSettings="matchProgressTimetableGridSelectionSettings"
                :dataSource="matchProgressTimetableInfoList"
                :aggregates="matchProgressTimetableGridAggregates"
                @visitEjsDropdownListEditTemplateChanged="onVisitEjsDropdownListEditTemplateChanged"
                @rowSelecting="matchProgressTimetableGridRecordClick"
                @headerCellInfo="matchProgressTimetableGridHeaderCellInfo"
                @queryCellInfo="matchProgressTimetableGridQueryCellInfo"
                @actionComplete="matchProgressTimetableGridActionComplete"
                @cellSelected="matchProgressTimetableGridCellSelected"
              />
            </div>
          </div>
        </section>
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-02">
          <div class="section-body">
            <div class="header">
              <div class="item" v-for="n in 5" :key="n">내장자{{ n }}</div>
              <div class="item group">단체팀</div>
              <div class="item group">캐디</div>
              <div class="item">카트번호</div>
            </div>
            <div class="content">
              <div class="item" v-for="n in 5" :key="n">{{ selectedTimetableInfo[`visitor${n}`] }}</div>
              <div class="item group">{{ selectedTimetableInfo.groupName }}</div>
              <div class="item group">{{ selectedTimetableInfo.caddies }}</div>
              <div class="item">{{ selectedTimetableInfo.cartNumber }}</div>
            </div>
          </div>
        </section>
      </article>
    </div>
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
body .article-section.section-01 {height: calc(100% - 68px);}
body .article-section.section-02 {height: 68px;}
body .article-section.section-02 .section-body {border: 1px solid #ccc; background-color: #fff;}
body .article-section.section-02 .section-body > .header {display: flex; height: 24px; border-bottom: 1px solid #e0e0e0; background-color: #f9f9f9;}
body .article-section.section-02 .section-body > .content {display: flex; height: 23px;}
body .article-section.section-02 .section-body > .header > .item,
body .article-section.section-02 .section-body > .content > .item {display: flex; align-items: center; justify-content: center; min-width: calc(100% / 10);}
body .article-section.section-02 .section-body > .header > .item:not(:last-child),
body .article-section.section-02 .section-body > .content > .item:not(:last-child) {border-right: 1px solid #e0e0e0;}
body .article-section.section-02 .section-body > .header > .item.group,
body .article-section.section-02 .section-body > .content > .item.group {flex: 1}
</style>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {sortBy as _sortBy} from 'lodash';
import {DATE_FORMAT_YYYY_MM_DD, getDayOfWeekCaption, getDayOfWeekCaptionColor, getFormattedDate} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDate from '@/components/common/datetime/InputDate';
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdx,
  commonCodesGetComName, commonCodesGetSortNo,
} from '@/utils/commonCodes';
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';
import Vue from 'vue';

const REQRE_TIME_FORMAT = {
  PREFIX: moment().format(DATE_FORMAT_YYYY_MM_DD),
  HOURS_PREFIX: '00시간',
  SUFFIX: ':00',
};

const timeToMin = time => {
  if (time) {
    const [h, m] = time.split(':');
    const hours = parseInt(h);
    const minutes = parseInt(m);
    return (hours * 60) + minutes;
  }
  return 0;
};

const minToTime = min => {
  const hours = Math.floor(min / 60);
  const minutes = Math.round(min % 60);
  return hours > 0
    ? `${hours.toString().padStart(2, '0')}시간 ${minutes.toString().padStart(2, '0')}분`
    : `${minutes.toString().padStart(2, '0')}분`;
};

export default {
  name: 'MatchProgressTimetable',
  components: {
    ReportView,
    ejsGridWrapper,
    InputDate,
    editMultipleColumnsPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      labels: {
        visitDate: '일자',
      },
      visitDateInfo: {
        bsnCode: null,
        dwCode: null,
      },
      courseDivTabList: commonCodesGetCommonCode('COURSE_CODE', true).map(item => {
        return {
          header: {text: item.comName},
          courseDiv: item.comCode,
        };
      }),
      courseDiv: '0',
      gridVisitEjsDropdownlistEditTemplateEventBus: new Vue(),
      matchProgressTimetableGridColumn: [
        {
          field: 'id',
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
          type: 'number',
        },
        {
          field: 'course',
          headerText: '출발코스',
          textAlign: 'center',
          allowEditing: false,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 95,
          type: 'string',
        },
        {
          field: 'time',
          headerText: '출발시간',
          textAlign: 'center',
          allowEditing: false,
          width: 95,
          type: 'string',
        },
        {
          headerText: '예약자',
          field: 'reserveName',
          allowEditing: false,
          width: 95,
          type: 'string',
        },
        {
          headerText: '캐디명',
          field: 'caddieName',
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          width: 120,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: 'OUT',
          textAlign: 'center',
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 95,
          type: 'string',
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              'startCourse',
              commonCodesGetCommonCode('COURSE_CODE', true),
              this.$refs.matchProgressTimetableGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus,
            ),
        },
        {
          field: 'firstStartTime',
          headerText: '전반시작',
          textAlign: 'center',
          allowEditing: true,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          field: 'firstEndTime',
          headerText: '전반종료',
          textAlign: 'center',
          allowEditing: true,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          field: 'firstReqreTime',
          headerText: '소요시간',
          textAlign: 'center',
          allowEditing: false,
          allowFiltering: false,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          field: 'endCourse',
          headerText: 'IN',
          textAlign: 'center',
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 95,
          type: 'string',
        },
        {
          field: 'lateStartTime',
          headerText: '후반시작',
          textAlign: 'center',
          allowEditing: true,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          field: 'lateEndTime',
          headerText: '후반종료',
          textAlign: 'center',
          allowEditing: true,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          field: 'lateReqreTime',
          headerText: '소요시간',
          textAlign: 'center',
          allowEditing: false,
          allowFiltering: false,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          headerText: '라운딩시간',
          field: 'roundTime',
          textAlign: 'center',
          allowEditing: false,
          allowFiltering: false,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          headerText: '총소요시간',
          field: 'totReqreTime',
          textAlign: 'center',
          allowEditing: false,
          allowFiltering: false,
          isTimeType: true,
          width: 95,
          type: 'string',
        },
        {
          headerText: '비고',
          field: 'remarks',
          textAlign: 'left',
          type: 'string',
        },
      ],
      matchProgressTimetableGridSelectionSettings: { mode: 'Both' },
      grid: [
        Edit,
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      matchProgressTimetableGridAggregates: [
        {
          columns: [
            {
              field: 'course',
              aggregationType: 'TotalCount',
              textAlign: 'right',
              stringFormat: '총 ${value}팀',
            },
            {
              field: 'time',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: () => {
                let count = 0;
                const endCount = this.$refs.matchProgressTimetableGrid.getGridBatchData()
                  .map(item => {
                    const arr = [
                      'firstStartTime',
                      'firstEndTime',
                      'lateStartTime',
                      'lateEndTime',
                    ];
                    for (const i of arr) {
                      if (!item[i] || item[i] === '00:00') {
                        count++;
                      }
                    }
                    return {
                    count: count >= 1 && count < 4
                      ? 1
                      : 0,
                    };
                  })
                  .reduce((acc, cur) => acc + cur.count, 0);
                return `진행 ${endCount}팀`;
              },
            },
            {
              field: 'reserveName',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: () => {
                return `종료 ${this.$refs.matchProgressTimetableGrid.getGridBatchData().filter(item =>
                  item.firstStartTime && item.firstStartTime !== '00:00' &&
                  item.firstEndTime && item.firstEndTime !== '00:00' &&
                  item.lateStartTime && item.lateStartTime !== '00:00' &&
                  item.lateEndTime && item.lateEndTime !== '00:00',
                ).length}팀`;
              },
            },
            {
              field: 'caddieName',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '평균소요시간',
            },
            {
              field: 'firstEndTime',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '전반:',
            },
            {
              field: 'firstReqreTime',
              aggregationType: 'TotalCaption',
              textAlign: 'center',
              customAggregate: () => {
                const currentTimeList = this.$refs.matchProgressTimetableGrid.getGridBatchData()
                  .filter(item => item.firstReqreTime && item.firstReqreTime !== '00:00');
                if (currentTimeList.length < 1) {
                  return 0;
                }
                const currentTime = (
                  currentTimeList
                    .map(item => timeToMin(item.firstReqreTime))
                    .reduce((acc, cur) => acc + cur, 0)
                ) / currentTimeList.length;
                return minToTime(currentTime);
              },
            },
            {
              field: 'lateEndTime',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '후반:',
            },
            {
              field: 'lateReqreTime',
              aggregationType: 'TotalCaption',
              textAlign: 'center',
              customAggregate: () => {
                const currentTimeList = this.$refs.matchProgressTimetableGrid.getGridBatchData()
                  .filter(item => item.lateReqreTime && item.lateReqreTime !== '00:00');
                if (currentTimeList.length < 1) {
                  return 0;
                }
                const currentTime = (
                  currentTimeList
                    .map(item => timeToMin(item.lateReqreTime))
                    .reduce((acc, cur) => acc + cur, 0)
                ) / currentTimeList.length;
                return minToTime(currentTime);
              },
            },
            {
              field: 'roundTime',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '총소요:',
            },
            {
              field: 'totReqreTime',
              aggregationType: 'TotalCaption',
              textAlign: 'center',
              customAggregate: () => {
                const currentTimeList = this.$refs.matchProgressTimetableGrid.getGridBatchData()
                  .filter(item => item.totReqreTime && item.totReqreTime !== '00:00');
                if (currentTimeList.length < 1) {
                  return 0;
                }
                const currentTime = (
                  currentTimeList
                    .map(item => timeToMin(item.totReqreTime))
                    .reduce((acc, cur) => acc + cur, 0)
                ) / currentTimeList.length;
                return minToTime(currentTime);
              },
            },
            {
              field: 'remarks',
              aggregationType: 'TotalCaption',
              textAlign: 'center',
              customAggregate: () => {
                const currentTimeList = this.$refs.matchProgressTimetableGrid.getGridBatchData()
                  .filter(item =>
                    item.firstEndTime &&
                    item.firstEndTime !== '00:00' &&
                    item.lateStartTime &&
                    item.lateStartTime !== '00:00');
                if (currentTimeList.length < 1) {
                  return 0;
                }
                const currentTime = (
                  currentTimeList
                    .map(item => Math.abs(timeToMin(item.lateStartTime) - timeToMin(item.firstEndTime)))
                    .reduce((acc, cur) => acc + cur, 0)
                ) / currentTimeList.length;
                return `(대기: ${minToTime(currentTime)})`;
              },
              textColor: 'blue',
            },
          ],
        },
      ],
      matchProgressTimetableInfoPureList: [],
      matchProgressTimetableInfoList: [],
      matchProgressTimetableDailyInfo: {},
      selectedMatchProgressTimetableInfo: {},
      searchConditions: {
        visitDate: new Date(),
      },
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
      isEditMultipleColumnsPopupOpen: false,
      isModifyMode: false,
    };
  },
  async created() {
    if (this.visitDateValueModel) {
      const {data: {bsnCode, dwCode}} = await GolfErpAPI.fetchCalenderInfo(this.visitDateValueModel, false);
      this.visitDateInfo.bsnCode = bsnCode;
      this.visitDateInfo.dwCode = dwCode;
    }
    this.courseDivTabList.unshift({
      header: {text: '전체'},
      courseDiv: '0',
    });
    await this.fetch();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.searchConditions.visitDate);
    },
    visitDateValueModel: {
      get() {
        return getFormattedDate(this.searchConditions.visitDate);
      },
      set(visitDateValueModel) {
        this.searchConditions.visitDate = moment(visitDateValueModel).toDate();
      },
    },
    selectedTimetableInfo() {
      const visitors = {};
      this.selectedMatchProgressTimetableInfo.visitors?.forEach(
        (item, index) => visitors[`visitor${index + 1}`] = `${item.name}(${item.lockerNumber})`);
      const caddies = this.selectedMatchProgressTimetableInfo.teamCaddies
        ?.map(caddie => caddie.caddie.name)
        ?.join(', ');
      const cartNumber = this.selectedMatchProgressTimetableInfo.teamCaddies
        ?.map(caddie => caddie.cartNumber)
        ?.join(', ');
      return {
        ...visitors,
        groupName: this.selectedMatchProgressTimetableInfo.groupName,
        caddies,
        cartNumber,
      };
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async onViewMatchProgressTimetable() {
      await this.fetch();
    },
    async onSaveMatchProgressTimetable() {
      const {changedRecords} = this.$refs.matchProgressTimetableGrid.getBatchChanges();
      if (changedRecords.length > 0) {
        try {
          await GolfErpAPI.putMatchProgressTimetable(changedRecords.map(item => {
            return {
              chkinId: item.id,
              startCourse: item.startCourse,
              firstStartTime: item.firstStartTime,
              firstEndTime: item.firstEndTime,
              endCourse: item.endCourse,
              lateStartTime: item.lateStartTime,
              lateEndTime: item.lateEndTime,
              inputPath: 'ERP',
              remarks: item.remarks,
            };
          }));
          await this.fetch();
          this.infoToast(this.$t('main.popupMessage.saved'));
        } catch (err) {
          throw new Error(err);
        }
      } else {
        this.errorToast(this.$t('main.popupMessage.noDataToSave'));
      }
    },
    handleSearchConditionsBsnDateChange(args) {
      this.fetch();
      this.visitDateInfo = args;
    },
    tabSelected(args) {
      this.courseDiv = this.courseDivTabList[args.selectedIndex].courseDiv;
      this.setCourse();
    },
    setCourse() {
      this.matchProgressTimetableInfoList = this.matchProgressTimetableInfoPureList
        .filter(item => this.courseDiv === '0' || item.course === this.courseDiv);
    },
    onMatchProgressTimetableGridSectionBodyKeyDown(e) {
      const gridInstance = this.$refs.matchProgressTimetableGrid;
      const editingCellField = gridInstance.editingCellField;
      const currentRowIndex = gridInstance.currentSelectedRowIndex;
      const getDataRowsCount = gridInstance.getDataRows().length;
      const dropdownListColumns = ['startCourse', 'endCourse'];
      switch (e.key) {
        case 'Tab':
          if (editingCellField === 'startCourse' && e.shiftKey) {
            if (currentRowIndex > 0 && currentRowIndex < getDataRowsCount) {
              gridInstance.editCell(currentRowIndex - 1, 'lateEndTime');
            }
          } else if (editingCellField === 'lateEndTime' && !e.shiftKey) {
            e.stopPropagation();
            e.preventDefault();
            if (currentRowIndex === getDataRowsCount - 1) {
              gridInstance.saveCell();
              gridInstance.editCell(0, 'startCourse');
            } else if (currentRowIndex < getDataRowsCount - 1) {
              gridInstance.editCell(currentRowIndex + 1, 'startCourse');
            }
          }
          break;
        case 'Enter':
          // eslint-disable-next-line no-case-declarations
          const fieldList = [
            'startCourse',
            'firstStartTime',
            'firstEndTime',
            'endCourse',
            'lateStartTime',
          ];
          if (fieldList.includes(editingCellField)) {
            e.stopPropagation();
            e.preventDefault();
            gridInstance.editCell(
              currentRowIndex,
              editingCellField === 'lateStartTime'
                ? 'lateEndTime'
                : fieldList[fieldList.findIndex(item => item === editingCellField) + 1],
            );
          } else if (editingCellField === 'lateEndTime') {
            e.stopPropagation();
            e.preventDefault();
            if (currentRowIndex === getDataRowsCount - 1) {
              // gridInstance.saveCell();
              // gridInstance.editCell(0, 'startCourse');
            } else if (currentRowIndex < getDataRowsCount - 1) {
              gridInstance.editCell(currentRowIndex + 1, 'startCourse');
            }
          } else {
            if (currentRowIndex === getDataRowsCount - 1) {
              e.target.addEventListener(
                'keydown',
                () => {
                  gridInstance.saveCell();
                  gridInstance.editCell(currentRowIndex, editingCellField);
                },
                {once: true},
              );
            }
          }
          break;
        case 'ArrowUp':
          if (editingCellField) {
            if (
              !dropdownListColumns.includes(editingCellField) &&
              currentRowIndex > 0
            ) {
              e.stopPropagation();
              e.preventDefault();
              gridInstance.editCell(currentRowIndex - 1, editingCellField);
            }
          }
          break;
        case 'ArrowDown':
          if (editingCellField) {
            if (
              !dropdownListColumns.includes(editingCellField) &&
              currentRowIndex < getDataRowsCount - 1
            ) {
              e.stopPropagation();
              e.preventDefault();
              gridInstance.editCell(currentRowIndex + 1, editingCellField);
            }
          }
          break;
        case 'Escape':
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onVisitEjsDropdownListEditTemplateChanged(args) {
      const {
        columnName,
        value,
        rowData: data,
      } = args;
      if (columnName === 'startCourse') {
        const gridInstance = this.$refs.matchProgressTimetableGrid;
        const modifiedRowIndex = gridInstance.getRowIndexByPrimaryKey(data.id);
        const jsonData = commonCodesGetCommonCodeByIdx('GAME_COURSE', 1)
          .find(i => i.comCode === value)?.jsonData;
        let endCourse = value;
        if (jsonData && jsonData.length > 0) {
          const data = JSON.parse(jsonData);
          if (data && data.length > 0) {
            endCourse = data[0].ATTRB;
          }
        }
        gridInstance.updateCell(modifiedRowIndex, 'endCourse', endCourse);
      }
    },
    matchProgressTimetableGridRecordClick(args) {
      this.selectedMatchProgressTimetableInfo = args.data;
    },
    matchProgressTimetableGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.matchProgressTimetableGridColumn
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.matchProgressTimetableGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    matchProgressTimetableGridQueryCellInfo(args) {
      const {
        cell,
        column: {field, allowEditing},
        data,
      } = args;
      const findIndex = this.matchProgressTimetableInfoList
        .findIndex(item => item.id === data.id);
      if (findIndex > 0) {
        if (
          this.matchProgressTimetableInfoList[findIndex].course !==
          this.matchProgressTimetableInfoList[findIndex - 1].course
        ) {
          cell.classList.add(this.$t('className.grid.devReservationPartDivLine'));
        }
      }
      [
        'firstReqreTime',
        'lateReqreTime',
        'roundTime',
        'totReqreTime',
      ].forEach(item => {
        if (field === item && data[item]) {
          cell.innerText = moment(`${REQRE_TIME_FORMAT.PREFIX} ${data[item]}${REQRE_TIME_FORMAT.SUFFIX}`)
            .format('HH시간 mm분')
            .replace(REQRE_TIME_FORMAT.HOURS_PREFIX, '');
        }
      });
      if ([
        'startCourse',
        'endCourse',
        'roundTime',
      ].includes(field)) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (allowEditing) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      } else {
        cell.style.backgroundColor = '#FFFCFC';
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.matchProgressTimetableGridColumn
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    matchProgressTimetableGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.matchProgressTimetableGrid?.getGridBatchCount() || 0,
      );
    },
    async print() {
      this.matchProgressTimetableDailyInfo = await GolfErpAPI.fetchMatchProgressTimetableDaily(
        moment(this.searchConditions.visitDate).format(DATE_FORMAT_YYYY_MM_DD),
      );

      const options = [
        {
          field: 'course',
          comCode: 'COURSE_CODE',
          format: 'comCode',
        },
        {
          field: 'startCourse',
          comCode: 'COURSE_CODE',
          format: 'comCode',
        },
        {
          field: 'endCourse',
          comCode: 'COURSE_CODE',
          format: 'comCode',
        },
      ];

      const result = this.$refs.matchProgressTimetableGrid.getGridBatchData(options)
        .map(item => {
          if (item.visitors?.length > 0) {
            item.visitors.map((visitor, index) => {
              item[`visitName${index + 1}`] = visitor.name;
            });
          }
          return item;
        });
      if (result.length < 1) {
        return this.errorToast(this.$t('report.popupMessage.noData'));
      }

      const searchOptionsList = [
        {
          key: '코스',
          value: this.courseDiv !== '0'
            ? commonCodesGetComName('COURSE_CODE', this.courseDiv)
            : '전체',
        },
      ];

      const searchOptions = searchOptionsList
        .filter(item => !!item.value)
        .map(item => item.key + ': ' + item.value)
        .join(', ');

      const reportData = {
        reportJson: {
          jsonData: result,
          partSum: {
            p1sum: result.filter(item => item.partDivision === '1')?.length,
            p2sum: result.filter(item => item.partDivision === '2')?.length,
            p3sum: result.filter(item => item.partDivision === '3')?.length,
          },
          dailyInfo: this.matchProgressTimetableDailyInfo,
          bsnDate: moment(this.searchConditions.visitDate).format('영업일자 : YYYY-MM-DD (ddd)'),
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    excel() {
      this.$refs.matchProgressTimetableGrid.excelExport();
    },

    // API
    async fetch() {
      const data = (await GolfErpAPI.fetchMatchProgressTimetable(
          moment(this.searchConditions.visitDate).format(DATE_FORMAT_YYYY_MM_DD),
      )).map(item => {
        return {
          ...item,
          ...item.visitRound,
          courseCode: item.course,
          startTime: item.time,
          caddieName: item.teamCaddies?.length > 0
            ? item.teamCaddies.map(caddie => caddie.caddie.name)?.join(', ')
            : '',
          cartNumber: item.teamCaddies?.length > 0
            ? item.teamCaddies.map(caddie => caddie.cartNumber)?.join(', ')
            : '',
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.course),
        };
      }).map(item => {
        if (!item.startCourse) {
          item.startCourse = item.course;
        }
        if (!item.endCourse) {
          const jsonData = commonCodesGetCommonCodeByIdx('GAME_COURSE', 1)
            .find(i => i.comCode === item.course)?.jsonData;
          if (jsonData && jsonData.length > 0) {
            const data = JSON.parse(jsonData);
            if (data && data.length > 0) {
              item.endCourse = data[0].ATTRB;
            }
          }
        }
        return item;
      }) || [];
      this.matchProgressTimetableInfoPureList = this.matchProgressTimetableInfoList = _sortBy(data, ['courseCodeSortNo', 'time']);
      this.setCourse();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.matchProgressTimetableGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.matchProgressTimetableGridSelectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both'};
      this.$refs.matchProgressTimetableGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async matchProgressTimetableGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.matchProgressTimetableGridColumn
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>
